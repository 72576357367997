import { I18n, T } from '@deity-io/falcon-i18n';
import React from 'react';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../../context/UserContext';
import Button from '../../UI/Button/Button';
import './UserAuth.scss';

const UserAuth = (props) => {
  const { user, logOut } = useUserContext();
  const { firstname, lastname } = user || {};

  return (
    <I18n>
      {t => (
        <div className="auth-wrapper">
          <div className="auth">
            <h2 className="auth__name">
              <T id="user.greeting" />, {firstname} {lastname}
            </h2>
            <p className="auth__info">
              <T id="user.info" />
            </p>
            <div className="auth__buttons">
              <Link className="auth__profile-link" to="/customer/account/" onClick={props.mobilePopup}>
                <Button className="btn_auth" trait="hover" text={t('personalArea.accountLabel')} type="button" />
              </Link>
              <Link className="auth__profile-link" to="/customer/account/orders/" onClick={props.mobilePopup}>
                <Button className="btn_auth" trait="hover" text={t('personalArea.orders')} type="button" />
              </Link>
            </div>
          </div>
          <ul className="auth__options">
            <li className="auth__option">
              <Link to="/customer/account/loyalty/" className="auth__link" onClick={props.mobilePopup}>
                <img src="/images/credit-card.svg" alt="card" className="auth__link-logo" />
                <span className="auth__link-text">
                  <T id="user.myCard" />
                </span>
              </Link>
            </li>
            <li className="auth__option">
              <Link to="/customer/account/edit/" className="auth__link" onClick={props.mobilePopup}>
                <img src="/images/emmezeta-book-icon.svg" alt="info" className="auth__link-logo" />
                <span className="auth__link-text">
                  <T id="user.myData" />
                </span>
              </Link>
            </li>
            <li className="auth__option">
              <Link to="/customer/account/wishlist/" className="auth__link" onClick={props.mobilePopup}>
                <img src="/images/emmezeta-whislist-icon.svg" alt="wishlist" className="auth__link-logo" />
                <span className="auth__link-text">
                  <T id="user.myWishlist" />
                </span>
              </Link>
            </li>
            <li className="auth__option">
              <div className="auth__link" onClick={logOut}>
                <img src="/images/emmezeta-close-icon.svg" alt="logout" className="auth__link-logo" />
                <span className="auth__link-text">
                  <T id="user.logout" />
                </span>
              </div>
            </li>
          </ul>
        </div>
      )}
    </I18n>
  );
};

export default UserAuth;
