import React, { memo, useState } from 'react';
import { T } from '@deity-io/falcon-i18n';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import HeaderUserImage from '../../../assets/icons/header-icon-user.svg';
import UserAuth from '../UserAuth/UserAuth';
import { useUserContext } from '../../../context/UserContext';
import { useAccountPopup } from '../../../hooks/useAccountPopup';
import useResponsive from '../../../hooks/useResponsive';
import { useMobileSearch } from '../../../hooks/useMobileSearch';
import loadable from '../../loadable';

const UserNotAuth = loadable(() => import('../UserNotAuth/UserNotAuth'), { ssr: false });

const UserAuthOrNotAuth = memo(props => {
  const { user } = useUserContext();
  return user ? <UserAuth mobilePopup={props.mobilePopup} /> : <UserNotAuth mobilePopup={props.mobilePopup} />;
});

let hoveredOnceGlobalState = false;

const HeaderMyAccount = () => {
  const { accountPopupVisible, hideAccPopup, showAccPopup } = useAccountPopup();
  const { isDesktop } = useResponsive();
  const { hideSearch } = useMobileSearch();

  const [hoveredOnce, setHoveredOnce] = useState(hoveredOnceGlobalState);

  const { user } = useUserContext();
  const { firstname, lastname } = user || {};
  const fullName = user ? `${firstname} ${lastname}` : '';

  function mobilePopup() {
    if (accountPopupVisible) {
      hideAccPopup();
    } else {
      showAccPopup();
    }
    hideSearch();
  }

  return (
    <div
      onMouseEnter={() => {
        if (isDesktop) {
          showAccPopup();
        }
        hideSearch();
        setHoveredOnce(true);
        hoveredOnceGlobalState = true;
      }}
      onMouseLeave={() => {
        if (isDesktop) {
          hideAccPopup();
        }
        hideSearch();
      }}
      className="header__menu-item header__menu-item_profile"
    >
      {hoveredOnce && (
        <div className={clsx('account', { 'account--show': accountPopupVisible })}>
          <UserAuthOrNotAuth mobilePopup={mobilePopup} />
        </div>
      )}

      {!isDesktop ? (
        <div onClick={mobilePopup}>
          <img className="header__profile-pic" src={HeaderUserImage} alt="user-profile" />
          <span className="header__profile-text">
            <div>
              <T id="header.profile" />
              <p>{fullName}</p>
            </div>
          </span>
        </div>
      ) : (
        <Link to="/customer/account/">
          <img className="header__profile-pic" src={HeaderUserImage} alt="user-profile" />
          <span className="header__profile-text">
            <T id="header.profile" />
            <p>{fullName}</p>
          </span>
        </Link>
      )}
    </div>
  );
};

export default HeaderMyAccount;
